html {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  width: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
  background: #e3e5e4;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  min-height: 100vh;
  height: 0px;
}

a {
  color: inherit;
  text-decoration: none;
}